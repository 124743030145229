import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ScaleLoader from 'react-spinners/ScaleLoader';

function Api() {
    const [apiResult, setApiResult] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    
    useEffect(() => {
        console.log('useEffect triggered with location.search:', location.search);
        const queryParams = new URLSearchParams(location.search);
        const wordQuery = queryParams.get('word');
        if (wordQuery) {
            console.log('Calling fetchResult with wordQuery:', wordQuery);
            fetchResult(wordQuery);
        }
    }, [location.search]);

    const fetchResult = async (word) => {
        console.log('fetchResult is called with word:', word);
        setIsLoading(true);
        const apiUrl = process.env.REACT_APP_API_URL; // 使用环境变量
        const apiKey = process.env.REACT_APP_API_KEY; // 使用环境变量
        const data = {
            inputs: {
                "origin": word,
                "sys.files": []
            },
            "response_mode": "blocking",
            "user": "art-speak-lite-user",
        };

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`
                },
                body: JSON.stringify(data)
            });
            const result = await response.json();
            console.log('API response for word:', word, result);
            setApiResult(result.data.outputs.text);
        } catch (error) {
            console.error("Error fetching data: ", error);
            setApiResult('Failed to fetch data.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            {
                isLoading ? 
                <ScaleLoader color="#4A90E2" />
                : <p>{apiResult}</p>
            }
        </div>
    );
}

export default Api;